import ajaxRequest from "./axiosConfig";
const register = {
  resetUser:(params)=>ajaxRequest("POST","/api/auth/seller_reset_password", 1, params, false),
  getCode: (params) =>
    ajaxRequest("POST", "/api/auth/get_verify_code", 1, params, false),
  sendStep1: (params) =>
    ajaxRequest("POST", "/api/auth/seller", 1, params, false),
  sendStep2: (params) =>
    ajaxRequest("POST", "/api/auth/seller_info", 1, params, false),
  sendStep3: (params) =>
    ajaxRequest("POST", "/api/auth/open_seller_request", 1, params, false),
};
const login = {
  login:(params)=>ajaxRequest("POST","/api/auth/seller_login", 1, params, false),
  test:(params)=>ajaxRequest("GET","/api/crm", 1, params, true),
}
const storeManagement = {
    //获取店铺列表
    getStoreList: (params) =>
        ajaxRequest("GET", "/api/seller/manage/store", 1, params, true),
    //获取店铺名称（联想查询）
    getStoreName: (params) =>
        ajaxRequest("GET", "/api/seller/manage/store_name", 1, params, true),
    //创建店铺
    getCreateStore: (params) =>
        ajaxRequest("POST", "/api/seller/manage/store", 1, params, true),
    //获取店铺详情
    getStoreDetail: (params,type) =>
        ajaxRequest("GET", `/api/seller/manage/store/${type}`, 1, params, true),
  //获取消息列表
  getMessageList: (params) =>
      ajaxRequest("GET", `/api/seller/manage/message_list`, 1, params, true),
  //点击消息
  clickMessage: (params) =>
      ajaxRequest("GET", `/api/seller/manage/click_message`, 1, params, true),
  //复制店铺
  copyCreateStore: (params) =>
      ajaxRequest("POST", "/api/seller/manage/store_dup", 1, params, true),
  //废弃店铺
  abandonStore: (params) =>
  ajaxRequest("GET", `/api/seller/manage/store_trash/${params}`, 1, {}, true),
};
export { register , login, storeManagement };
