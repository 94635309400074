module.exports = {
  devServer: {
    // 开发环境下的配置
    host: "0.0.0.0",
    port: 8888,
    disableHostCheck: true,
    proxy: {
      "/wapi": {
        target: process.env.VUE_APP_REQUEST_TEST,
        // target: "https://fpp-seller-core-test.service.funpinpin.com/",
        pathRewrite: { "^/wapi": "" },
        changeOrigin: true
      },
      "/api": {
        target: process.env.VUE_APP_REQUEST,
        // target: "https://fpp-seller-core-test.service.funpinpin.com/",
        pathRewrite: { "^/api": "" },
        changeOrigin: true
      }
    },
    before: app => {}
  },
  lintOnSave: false
};
