/** @format */
import {devServer} from "../../../vue.config";
const {proxy} = devServer;
import {handleResponseErrors} from "@/assets/js/utils";
import axios from "axios";
import qs from "qs";
import {
    showFullScreenLoading,
    tryHideFullScreenLoading
} from "./axiosHelperLoading";

// 请求拦截器
axios.interceptors.request.use(
    config => {
        // showFullScreenLoading();
        return config;
    },
    error => {
        // 对请求错误做些什么
        return Promise.reject(error);
    }
);

// 响应拦截器
axios.interceptors.response.use(
    // 请求成功
    res => {
        // tryHideFullScreenLoading();
        if (res.status === 200 && res.data.code != undefined) {
            return Promise.resolve(res.data);
        } else {
            return Promise.reject(res);
        }
    },
    // 请求失败
    error => {
        // tryHideFullScreenLoading();
        if (error.message.includes('timeout')) {   // 判断请求异常信息中是否含有超时timeout字符串
            handleResponseErrors(9000, '请求超时');
            return Promise.reject(error);// reject这个错误信息
        }
        const {response} = error;
        if (response) {
            // 请求已发出，但是不在200的范围
            // 对接对应返回处理方法（建议分离对应的js文件）
            return Promise.reject(response);
        } else {
            // 处理断网的情况
        }
    }
);

function ajaxRequest(method, url, type, params, token = true) {
    let contentType = [
        "application/x-www-form-urlencoded",
        "application/json",
        "multipart/form-data"
    ];
    let headers = {"content-type": contentType[type]};
    let Ftoken = localStorage.getItem("token")
    if (token === true&&Ftoken)
        headers["Authorization"] = "Bearer " + Ftoken;
    if (type === 0) params = qs.stringify(params);
    if (process.env.NODE_ENV != "development") {
        for (const key in proxy) {
            const regString = Object.keys(proxy[key].pathRewrite)[0];
            const regExp = RegExp(regString);
            if (url.match(regExp)) {
                axios.defaults.baseURL = proxy[key].target;
                url = url.replace(regExp, proxy[key].pathRewrite[regString]);
                console.log(proxy[key].target, url);
            }
        }
    }
    let axiosOptions = {
        method,
        url,
        headers,
        withcredentials: true,
        timeout: 100000
    };
    if (method.toUpperCase() === "GET") {
        axiosOptions["params"] = params;
    } else {
        axiosOptions["data"] = params;
    }
    return axios(axiosOptions);
}

export default ajaxRequest;
